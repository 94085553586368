import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { graphql } from 'gatsby'
import PageHeader from '~components/PageHeader'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import Image from '~components/Image'
import RichText from '~components/RichText'
import ContactForm from '~components/ContactForm'

const Register = ({ data }) => {
	
	const { page } = data

	const colors =[
		'lightGreen',
		'green',
		'blue'
	]

	return (
		<Wrap>
			<Global styles={css`
				body{
						background-color: var(--white);
					}
				`}
			/>
			<StyledPageHeader 
				color='green' 
				image={page.headerImage} 
				title={page.headLine}
				text={page.introText}
			/>
			<Steps>
				<Flex>
					{page.steps.map((step, i) => (
						<Step key={step._key}>
							<StepImage image={step.image}/>
							<StepContent>
								<Number color={colors[i]}>
									{i + 1}
								</Number>
								<StepText>
									<RichText content={step.text}/>
								</StepText>
							</StepContent>
						</Step>
					))}
				</Flex>
			</Steps>
			<RegisterSection>
				<RegTitle >Register</RegTitle>
				<ContactForm successMessage={page.registerSuccessMessage} aboutPlaceholder={page.aboutPlaceholderText}/>
			</RegisterSection>
			<DataViewer data={page} name="page"/>
		</Wrap>
	)
}

const Wrap = styled.div`
`
const StyledPageHeader = styled(PageHeader)`
	${mobile}{
		margin-bottom: var(--m);
	}
`
const Steps = styled(Section)`
	padding: var(--xl) 0;
`
const Flex = styled.div`
	grid-column: span 12;
	max-width: 950px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	width: 100%;
`
const Step = styled.div`
	width: 50%;
	${mobile}{
		width: 100%;
		margin-bottom: var(--xl);
	}
`
const StepImage = styled(Image)`
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: 0;
	}
`
const StepContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const Number = styled.div`
	background-color: ${props => props.color && `var(--${props.color})`} ;	
	color: var(--white);
	width: 52px;
	height: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	margin-bottom: 32px;
	${mobile}{
		margin-bottom: var(--s);
	}
`
const StepText = styled.div`
	text-align: center;
	max-width: 265px;
	margin: 0 auto; 
`
const RegisterSection = styled(Section)`
	background-color: var(--lightBlue);
	padding: var(--xl);
`
const RegTitle = styled.h3`
	grid-column: span 12;
	text-align: center;
	max-width: 100%;
	margin-bottom: var(--xl);
`

export const query = graphql`
  query RegisterQuery($id: String) {
    page: sanityRegister(id: {eq: $id}) {
			title
			seo{
				...seo
			}
			headerImage {
				...imageWithAlt
			}
			title
			headLine
			introText: _rawIntroText(resolveReferences: {maxDepth: 5})
			steps {
				text: _rawText(resolveReferences: {maxDepth: 5})
				image {
					...imageWithAlt
				}
				_key
			}
			registerSuccessMessage: _rawRegisterSuccessMessage(resolveReferences: {maxDepth: 5})
			registerImage {
				...imageWithAlt
			}
			aboutPlaceholderText
		}
  }
`

Register.propTypes = {
	data: PropTypes.object
}

export default Register